/* THIS LINE FIX FRESH DESK BALISE */
:root {
  --primaryColorMain: #f29218;
  --primaryColorDark: #f7ba6d;
  --primaryColorLight: #e3f3fc;
  --secondaryColorMain: #f7ba6d;
  --RectangleColorLight: #c4c4c433;
  --RectangleColor: #c4c4c4;
  --highlightColorMain: #dddddd;
  --underlightColorMain: #c5c5c5;
  --backgroundGrey: #eeeeee;
  --green: #55b25a;
  --red: #c73124;
  --defaultStyleColor: #c5c5c5;
  --titleStyleColor: #c5c5c5;
  --topicStyleColor: #67bdf2;
  --qcmStyleColor: #67bdf2;
  --imageStyleColor: #2FDE48;
  --textStyleColor: #D0F540;
  --qcsStyleColor: #005BF5;
  --mesureStyleColor: #F5C638;
  --dateStyleColor: #F5DF39;
}

body {
  font-family: Poppins, sans-serif;
  font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

h1 {
  font-size: 1.75rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1.15rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.75rem;
}

table {
  margin-bottom: 0 !important;
}

th,
td {
  font-size: 0.8rem !important;
  vertical-align: middle !important;
  text-align: center !important;
  padding: 0.1rem !important;
}

tr {
  cursor: pointer;
  font-size: 0.8rem;
}

.app_title {
  color: var(--primaryColorMain);
  font-weight: bold;
  margin-left: 2rem;
  margin-bottom: 0;
  margin-top: 0.45rem;
}

.main-page {
  width: 100%;
  min-height: 100vh;
  background-image: url('/src/assets/images/background.png');
  background-size: 100% auto;
  background-repeat: repeat-y;
}

.doctreen-button {
  background-color: var(--primaryColorMain) !important;
  color: white !important;
  border-color: var(--primaryColorMain) !important;
  font-size: 0.9rem !important;
  padding: 0.1rem 0.3rem;
}

.doctreen-button:hover {
  background-color: var(--secondaryColorMain) !important;
  color: white !important;
  border-color: var(--primaryColorMain) !important;
}

.doctreen-button:focus {
  outline: none;
}

.page-view {
  padding: 5rem;
}

.iframe-box {
  width: 100%;
  height: 100%;
  padding-top: 1rem;
}

.doctreen-iframe {
  width: 100%;
  height: 100%;
}

.custom-ck-editor {
  margin-top: 0.5rem;
  margin-bottom: 3rem;
}

.ck-content {
  height: 60vh;
}

.logo-simple {
  width: 50px;
}

.patient-info {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: white;
  height: 3rem;
  padding-left: 1rem;
  border-bottom: 1px dark solid;
}

.patient-info>div {
  width: 25%;
}

.paper {
  overflow: auto;
  top: initial;
  width: 100%;
  position: relative !important;
}

.drawer {
  overflow: hidden;
  flex-shrink: 0;
  height: 100%;
}

.cr-iframe {
  padding: 1rem 1rem 1rem 1rem;
  height: calc(100vh - 64px);
}

.cr-view {
  padding: 2rem 5rem 5rem 5rem;
  height: calc(100vh - 64px);
  overflow-y: scroll;
}

.page-document {
  background-color: white;
  height: 80vh;
  padding: 2rem 5rem;
  overflow-y: scroll;
}

.page-center {
  height: calc(100vh - 64px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-right {
  right: 1rem;
}


.background-white {
  background-color: white;
  padding: 0.75rem 0;
}

.logo-doctreen {
  height: 7rem;
}

.logo-qr {
  top: 0.5rem;
  height: 5rem;
}

.logo-left {
  left: 0rem;
}

.logo-right {
  right: 0rem;
  top: 0.5rem;
}

.logo-signature {
  right: 9.75rem;
  top: 0rem;
  opacity: 0.05 !important;
}

.logo-tahiti {
  width: 80px;
  z-index: 1;
  opacity: 0.1;
}

.logo-caduce {
  height: 5rem;
  z-index: 1;
  opacity: 0.1;
}

#printContent .w-75 {
  width: 100% !important;
}

.logo-header {
  height: 3rem;
}

.reporting-header {
  min-height: 5rem;
}

#reporting-content .logo-header {
  height: 3rem;
}

#reporting-content .logo-left {
  left: 4rem;
}

#reporting-content .logo-right {
  right: 11rem;
  top: 1rem;
}

.author-sign {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: auto;
  padding: 1rem;
}

.author-sign::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/src/assets/images/tahiti.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.1;
}

#reporting-content .logo-signature {
  right: 9.75rem;
  top: 0rem;
  opacity: 0.05 !important;
}

#reporting-content .logo-tahiti {
  width: 100px;
  z-index: 1;
  opacity: 0.05;
}

#reporting-content .logo-qr {
  height: 6rem;
}

.small-text {
  font-size: small;
}

.w-33 {
  width: 33%;
}

.synthese-motif {
  width: max-content;
  justify-content: center;
  align-items: center;
}

.synthese-motif>* {
  width: 100%;
}

.synthese-motif>:first-child {
  margin-bottom: 0.3rem;
}

.close-icon {
  right: 1rem;
  top: 1rem;
}

#printContent {
  width: 80%;
}

.no-style:link {
  text-decoration: none;
}

.no-style:visited {
  text-decoration: none;
}

.no-style:hover {
  text-decoration: none;
}

.no-style:active {
  text-decoration: none;
}
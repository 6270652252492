.Modal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1300;
  overflow: scroll;
}

.Modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}


.Modal-content {
  position: relative;
  left: 50vw;
  top: 5vh;
  z-index: 2;
  font-size: 1rem;
  padding: 1rem;
  border: 1px solid lightgray;
  border-radius: 1rem;
  background: white;
  transform: translate(-50%, 0);
  overflow-x: clip;
  overflow-y: auto;
  width: 90vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
}

.ModalHeader {
  border-bottom: 1px solid lightgray;
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.ModalBody {
  height: 100%;
}

.ModalFooter {
  border-top: 1px solid lightgray;
  margin-top: 0.5rem;
  font-size: 1.2rem;
  text-align: right;
}

.top-right {
  top: 0rem;
  right: 1rem;
}